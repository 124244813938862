.image-wrapper {
    width: 20rem;
    padding-top: 20rem;
    position: relative;
}
.image-wrapper img {
    border-radius: 60px;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}